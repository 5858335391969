import React, { FC } from "react";

import {
  DefaultTemplateContext,
  DefaultTemplateProps,
  LandingPageTemplate,
  LandingPageTemplateProps,
  PageTemplate,
  PageTemplateProps,
  PanelTemplate,
  PanelTemplateProps,
} from "templates";

const safelyGetFrontMatter = (pageContext: DefaultTemplateContext) =>
  pageContext && pageContext.frontmatter ? pageContext.frontmatter : {};

const DefaultTemplate: FC<DefaultTemplateProps> = (props) => {
  const { template } = safelyGetFrontMatter(props.pageContext);

  switch (template) {
    case "landing-page":
      return (
        <LandingPageTemplate {...(props as LandingPageTemplateProps)} isMDX />
      );
    case "panel":
      return <PanelTemplate {...(props as PanelTemplateProps)} isMDX />;
    case "page":
    default:
      return <PageTemplate {...(props as PageTemplateProps)} isMDX />;
  }
};

export default DefaultTemplate;
