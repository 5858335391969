// extracted by mini-css-extract-plugin
export var defaultSection = "Section-module--defaultSection--2chuX";
export var contentSection = "Section-module--contentSection--Ge_0_";
export var ctaSection = "Section-module--ctaSection--2KuIA";
export var featureSection = "Section-module--featureSection--2uS_r";
export var heelSection = "Section-module--heelSection--3VJOW";
export var heroSection = "Section-module--heroSection--2ztVG";
export var layoutSection = "Section-module--layoutSection--oMFFX";
export var navbarSection = "Section-module--navbarSection--3fqff";
export var sectionCol = "Section-module--sectionCol--1tX5z";
export var sectionCompact = "Section-module--sectionCompact--AsDDj";
export var sectionFluid = "Section-module--sectionFluid--2wwrz";
export var sectionFull = "Section-module--sectionFull--3VVsU";
export var sectionInherit = "Section-module--sectionInherit--38WlH";
export var sectionRow = "Section-module--sectionRow--p5mJW";
export var sectionTransparent = "Section-module--sectionTransparent--3KGSY";