import React, { forwardRef } from "react";
import clsx from "clsx";

import { BoxPrimitive as Box, BoxProps } from "components";

import { ContainerPattern } from "./types";
import {
  fluidContainer,
  cardContainer,
  contentContainer,
  defaultContainer,
  panelContainer,
  wideContainer,
} from "./Container.module.scss";

export interface ContainerProps<E extends HTMLElement = HTMLDivElement>
  extends Omit<BoxProps<E>, "is" | "width"> {
  is?: ContainerPattern;
}

export const ContainerPrimitive = <E extends HTMLElement = HTMLDivElement>({
  as = "figure",
  children,
  className,
  is = "default",
  ...rest
}: ContainerProps<E>) => (
  <Box
    as={as}
    {...(rest as BoxProps)}
    className={clsx(
      is === "fluid" ? fluidContainer : "container",
      is === "card" && cardContainer,
      is === "content" && contentContainer,
      is === "default" && defaultContainer,
      is === "panel" && panelContainer,
      is === "wide" && wideContainer,
      className
    )}
  >
    {children}
  </Box>
);

export const Container = forwardRef<
  HTMLDivElement,
  ContainerProps<HTMLDivElement>
>((props, ref) => <ContainerPrimitive {...props} innerRef={ref} />);
