import React, {
  FC,
  MouseEvent,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Switch, { ReactSwitchProps } from "react-switch";

type Theme = "dark" | "light";

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: (event: MouseEvent) => void;
}

// check if color scheme preference for user's OS is configured for dark mode.
const prefersDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;

const ThemeContext = createContext<ThemeContextProps>({
  theme: "light",
  toggleTheme: () => {},
});

export const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light");

  useEffect(() => {
    // get theme value from localStorage
    const storedTheme: Theme = localStorage.getItem("theme") as Theme;
    if (storedTheme !== null) {
      setTheme(storedTheme);
    } else if (prefersDarkMode()) {
      setTheme("dark");
    }
  }, []);

  const toggleTheme = () => {
    const isDark = theme === "dark";
    localStorage.setItem("theme", isDark ? "light" : "dark");
    setTheme(isDark ? "light" : "dark");
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export type ThemeSwitchPattern = "primary" | "secondary";

export interface ThemeSwitchProps extends ReactSwitchProps {
  is?: ThemeSwitchPattern;
}

export const ThemeSwitch: FC<ThemeSwitchProps> = ({
  children,
  className,
  is,
  ...rest
}) => {
  const { theme } = useTheme();

  const getColor = () => {
    switch (is) {
      case "secondary":
        return theme === "dark" ? "#b4aea7" : "#3a3835";
      case "primary":
      default:
        return theme === "dark" ? "#3a3835" : "#b4aea7";
    }
  };

  const getHandleColor = () => {
    switch (is) {
      case "secondary":
        return theme === "dark" ? "#3a3835" : "#b4aea7";
      case "primary":
      default:
        return theme === "dark" ? "#b4aea7" : "#3a3835";
    }
  };

  return (
    <Switch
      className={className}
      borderRadius={3}
      checkedIcon={false}
      uncheckedIcon={false}
      offColor={getColor()}
      onColor={getColor()}
      onHandleColor={getHandleColor()}
      offHandleColor={getHandleColor()}
      height={20}
      width={40}
      {...(rest as ReactSwitchProps)}
    >
      {children}
    </Switch>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === null) {
    throw new Error("useTheme must be called inside ThemeProvider.");
  }

  return context;
};
