import React, { FC } from "react";
import { Helmet, HelmetProps, HtmlProps } from "react-helmet-async";

import { useSiteMetadataQuery } from "graphql";

import { SEOProps } from "./types";

export const SEO: FC<SEOProps> = ({
  children,
  defaultTitle,
  description,
  htmlAttributes,
  image,
  keywords,
  lang,
  meta,
  name,
  prefix,
  title,
  type = "website",
  url,
  ...rest
}) => {
  const metadata = useSiteMetadataQuery();

  const setHtmlAttributes = () => {
    return {
      ...htmlAttributes,
      lang: lang || metadata?.lang,
      prefix: prefix || metadata?.tagPrefix,
    } as HtmlProps;
  };

  const setMetaTags = () =>
    meta?.concat(
      keywords?.length
        ? {
            name: "keywords",
            content: keywords.join(", "),
          }
        : []
    );

  return (
    <Helmet
      defaultTitle={defaultTitle || metadata?.name || name}
      htmlAttributes={setHtmlAttributes()}
      meta={setMetaTags()}
      title={title || metadata?.title}
      titleTemplate={`${name || metadata?.name} | %s`}
      {...(rest as HelmetProps)}
    >
      <meta name="description" content={description || metadata?.description} />
      <meta name="image" content={image || metadata?.image} />

      <meta property="og:url" content={url || metadata?.siteUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title || metadata?.title} />
      <meta
        property="og:description"
        content={description || metadata?.description}
      />
      <meta property="og:image" content={image || metadata?.image} />
      {/* <meta
        property="fb:app_id"
        content={process.env.FACEBOOK_APP_ID || ""}
      /> */}

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta
        name="twitter:creator"
        content={metadata?.socialMedia?.twitter || ""}
      /> */}
      <meta name="twitter:title" content={title || metadata?.title} />
      <meta
        name="twitter:description"
        content={description || metadata?.description}
      />
      <meta name="twitter:image" content={image} />

      {children}
    </Helmet>
  );
};
