import React, { forwardRef } from "react";
import { random } from "lodash";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps, TextPattern } from "components";

import { ListItemPrimitive as ListItem, ListItemProps } from "./item";

export type ListPattern = "default" | "inline" | "menu";

export interface ListProps<E extends HTMLElement = HTMLUListElement>
  extends Omit<TextProps<E>, "is"> {
  items?: ListItemProps[];
  is?: ListPattern;
  text?: TextPattern;
}

export const ListPrimitive = <E extends HTMLElement = HTMLUListElement>({
  as = "ul",
  children,
  className,
  is,
  items = undefined,
  text,
  ...rest
}: ListProps<E>) => (
  <Text
    as={as}
    is={text}
    {...(rest as TextProps)}
    className={clsx(
      "list-square",
      is === "inline" && "flex flex-row flex-wrap",
      is === "menu" && "flex flex-col flex-full flex-wrap",
      className
    )}
  >
    {items?.length &&
      items.map(({ children, ...rest }) => (
        <ListItem key={random(16)} {...(rest as ListItemProps)}>
          {children}
        </ListItem>
      ))}
    {children}
  </Text>
);

export const List = forwardRef<HTMLUListElement, ListProps<HTMLUListElement>>(
  (props, ref) => <ListPrimitive {...props} innerRef={ref} />
);
