import React, { FC } from "react";
import { getSrc } from "gatsby-plugin-image";
import { find } from "lodash";

import { Container, Hero } from "components";
import { SEO } from "containers";
import { PanelLayout } from "layouts";
import { useMediaQuery } from "graphql";

import { PanelTemplateProps } from "./types";

export const PanelTemplate: FC<PanelTemplateProps> = ({
  children,
  isMDX = true,
  location,
  pageContext: {
    excerpt,
    frontmatter: {
      description,
      hero,
      layout,
      main,
      page,
      seo,
      showHeader = true,
      title,
    },
  },
}) => {
  const media = useMediaQuery();
  const image = find(media, ["name", `${page}-hero`]);

  return (
    <>
      <SEO
        title={seo?.title || title}
        description={
          seo?.description
            ? seo?.description
            : description
            ? description
            : excerpt
        }
        image={image && getSrc(image.childImageSharp.gatsbyImageData)}
        url={location?.href}
        {...seo}
      />
      <PanelLayout
        location={location}
        isMDX={isMDX}
        main={main}
        media={
          image && {
            image,
            mod: "background fixed",
          }
        }
        {...layout}
      >
        {showHeader && (
          <Hero
            is="panel"
            {...hero}
            caption={{ heading: title, ...hero?.caption }}
          />
        )}
        {main?.is === "content" ? (
          <Container as="article" is="content">
            {children}
          </Container>
        ) : (
          children
        )}
      </PanelLayout>
    </>
  );
};

export default PanelTemplate;
