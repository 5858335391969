import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Button,
  ButtonProps,
  FormProps,
  FormField,
  FormFieldProps,
} from "components";

export const renderField = (field: FormFieldProps) => {
  switch (field.type) {
    case "file":
    case "select":
    case "text":
    default:
      return <FormField {...(field as FormFieldProps)} />;
  }
};

export const encodeData = (data: any) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

export const Form: FC<FormProps> = ({
  as = "form",
  actions,
  button = "Submit",
  buttonProps,
  children,
  errorMessage = "Fill out all the required fields and try again.",
  hasErrors,
  isSubmitSuccessful,
  method,
  name,
  netlify = "true",
  netlifyHoneypot = "bot-field",
  netlifyRecaptcha = "false",
  successMessage,
  onSubmit,
  ...rest
}) => (
  <Box
    as={as}
    data-netlify={netlify}
    data-netlify-honeypot={netlifyHoneypot}
    data-netlify-recaptcha={netlifyRecaptcha}
    name={name}
    method={method}
    onSubmit={onSubmit}
    {...(rest as BoxProps)}
  >
    {children}
    {button && (
      <Box
        {...actions}
        className={clsx(
          "flex col-span-full items-center justify-between mt-4 order-last",
          actions?.className
        )}
      >
        <Button
          type="submit"
          color="secondary"
          size="lg"
          {...(buttonProps as ButtonProps)}
        >
          {button}
        </Button>
      </Box>
    )}
  </Box>
);
