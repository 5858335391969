import React, { FC, useState } from "react";
import clsx from "clsx";

import { BoxColor, LinkPattern, Section, SectionProps } from "components";
import { Copyright, CopyrightProps } from "containers";
import {
  ThemeSwitch,
  ThemeSwitchProps,
  ThemeSwitchPattern,
  useTheme,
} from "contexts";
import { GatsbyLocation } from "types";

export type LayoutFooterPattern = "page";

export interface LayoutFooterProps extends Omit<SectionProps, "is" | "color"> {
  activeClassName?: string;
  color?: BoxColor | LinkPattern | ThemeSwitchPattern;
  copyright?: CopyrightProps;
  isHidden?: boolean;
  is?: LayoutFooterPattern;
  location?: GatsbyLocation;
  showCopyright?: boolean;
  showThemeSwitch?: boolean;
  themeSwitch?: ThemeSwitchProps;
}

export const LayoutFooter: FC<LayoutFooterProps> = ({
  as = "footer",
  children,
  className,
  container,
  color = "secondary",
  copyright,
  is = "page",
  isHidden = false,
  location,
  showCopyright = true,
  showThemeSwitch = true,
  themeSwitch,
  ...rest
}) => {
  const [switchOn, setSwitchOn] = useState(false);
  const { toggleTheme } = useTheme();

  if (isHidden) return null;

  return (
    <Section
      as={as}
      is="navbar"
      color={color as BoxColor}
      {...(rest as SectionProps)}
      container={{
        ...container,
        className: clsx(
          "flex flex-row flex-wrap",
          "content-between items-end justify-between",
          container?.className
        ),
      }}
      className={clsx(
        "items-end text-center sm:text-left",
        is === "page" && "pt-12 md:pt-14 xl:pt-16",
        className
      )}
    >
      {children}
      {showCopyright && (
        <Copyright
          {...copyright}
          link={{ is: color as LinkPattern, ...copyright?.link }}
        />
      )}
      {showThemeSwitch && (
        <ThemeSwitch
          is={color as ThemeSwitchPattern}
          className="mt-4"
          {...themeSwitch}
          checked={switchOn}
          onChange={(event: any) => {
            setSwitchOn(!switchOn);
            toggleTheme(event);
          }}
        />
      )}
    </Section>
  );
};
