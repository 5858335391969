import React, { forwardRef } from "react";
import clsx from "clsx";

import { BoxPrimitive as Box, BoxProps } from "components";

import { getTextPattern, getTextSize } from "./utils";
import { TextPattern, TextSize } from "./types";

import { defaultText } from "./Text.module.scss";

export interface TextProps<E extends HTMLElement = HTMLFontElement>
  extends Omit<BoxProps<E>, "size" | "to"> {
  is?: TextPattern;
  size?: TextSize;
  to?: string;
}

export const TextPrimitive = <E extends HTMLElement = HTMLFontElement>({
  as = "span",
  children,
  className,
  is = "body",
  size,
  ...rest
}: TextProps<E>) => (
  <Box
    as={as}
    {...(rest as BoxProps)}
    className={clsx(
      defaultText,
      is && getTextPattern(is, size),
      size && getTextSize(size),
      className
    )}
  >
    {children}
  </Box>
);

export const Text = forwardRef<HTMLFontElement, TextProps<HTMLFontElement>>(
  (props, ref) => <TextPrimitive {...props} innerRef={ref} />
);
