import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxColor,
  BoxProps,
  Link,
  LinkPattern,
  LinkProps,
} from "components";

import {
  BrandLogo,
  BrandLogoProps,
  BrandName,
  BrandNameProps,
} from "./components";

import { brandContainer } from "./Brand.module.scss";

export interface BrandProps extends Omit<BoxProps, "color" | "name"> {
  color?: BoxColor | LinkPattern;
  isShort?: boolean;
  linkTo?: string;
  logo?: BrandLogoProps;
  name?: BrandNameProps;
  showLink?: boolean;
  showLogo?: boolean;
  showName?: boolean;
}

export const Brand: FC<BrandProps> = ({
  children,
  className,
  color = "primary",
  isShort = true,
  linkTo = "/",
  logo,
  name,
  showLink = true,
  showLogo = false,
  showName = true,
  ...rest
}) => {
  const renderBrand = () => (
    <>
      {showLogo && <BrandLogo {...logo} />}
      {showName && (
        <BrandName isShort={isShort} {...name}>
          {children}
        </BrandName>
      )}
    </>
  );

  return (
    <>
      {showLink ? (
        <Link
          is={color as LinkPattern}
          {...(rest as LinkProps)}
          className={clsx(brandContainer, className)}
          to={linkTo}
        >
          {renderBrand()}
        </Link>
      ) : (
        <Box
          color={color as BoxColor}
          {...(rest as BoxProps)}
          className={clsx(brandContainer, className)}
        >
          {renderBrand()}
        </Box>
      )}
    </>
  );
};
