import React, { FC } from "react";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps } from "components";
import { useBrandQuery } from "graphql";

import { brandName, brandNameTitle } from "./BrandName.module.scss";

export interface BrandNameProps extends TextProps {
  isShort?: boolean;
}

export const BrandName: FC<BrandNameProps> = ({
  children,
  className,
  isShort,
  ...rest
}) => {
  const { name, title } = useBrandQuery();

  return (
    <Text
      is="subtitle"
      className={clsx(brandName, className)}
      {...(rest as TextProps)}
    >
      {children ? (
        children
      ) : isShort ? (
        name
      ) : (
        <>
          {name}
          <span className={brandNameTitle}>{title}</span>
        </>
      )}
    </Text>
  );
};
