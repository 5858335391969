import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImageProps } from "types";

export interface HomeMediaQuery {
  media: {
    nodes: GatsbyImageProps[];
  };
}

export const homeMediaFragment = graphql`
  fragment HomeMediaFragment on File {
    childImageSharp {
      gatsbyImageData(
        formats: [AUTO, WEBP, AVIF]
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
    id
    name
  }
`;

export const useHomeMediaQuery = () => {
  const {
    media: { nodes },
  }: HomeMediaQuery = useStaticQuery(
    graphql`
      query HomeMediaQuery {
        media: allFile(
          filter: {
            absolutePath: { regex: "/assets/media/" }
            name: { regex: "/home/" }
          }
        ) {
          nodes {
            ...HomeMediaFragment
          }
        }
      }
    `
  );

  return nodes;
};
