import React, { FC } from "react";
import clsx from "clsx";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

import {
  InputType,
  SelectOption,
  Text,
  TextInput,
  TextInputProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

import {
  formField,
  formFieldLabel,
  formFieldMessage,
} from "./FormField.module.scss";

export interface FormFieldProps extends WrapperProps {
  error?: FieldError;
  errorMessage?: string;
  id?: string;
  input?: TextInputProps;
  label?: string;
  name: string;
  options?: readonly SelectOption[];
  register?: UseFormRegisterReturn;
  type?: InputType;
}

export const FormField: FC<FormFieldProps> = ({
  children,
  className,
  error,
  errorMessage = "This field is required",
  id,
  input,
  label,
  name,
  options,
  register,
  type = "text",
  ...rest
}) => (
  <Wrapper
    as="div"
    {...(rest as WrapperProps)}
    className={clsx(formField, className)}
  >
    {label && (
      <Text
        as="label"
        is="meta"
        className={formFieldLabel}
        htmlFor={id || name}
      >
        {label}
      </Text>
    )}
    {children ? (
      children
    ) : (
      <TextInput
        id={id || name}
        name={name}
        register={register}
        type={type}
        {...input}
      />
    )}
    {error && (
      <Text is="caption" className={formFieldMessage}>
        {error.message || errorMessage}
      </Text>
    )}
  </Wrapper>
);
