import React, { FC } from "react";
import { find } from "lodash";

import { Box, Media, Section, SectionProps } from "components";
import { useHomeMediaQuery } from "graphql";

export type HomeMediaPattern = "before-after" | "hero";

export interface HomeMediaProps extends Omit<SectionProps, "is"> {
  is: HomeMediaPattern;
}

export const HomeMedia: FC<HomeMediaProps> = ({
  children,
  className,
  is,
  isFluid = true,
  mod = "compact",
}) => {
  const homeMedia = useHomeMediaQuery();
  const homeHero = find(homeMedia, ["name", "home-hero"]);
  const homeBefore = find(homeMedia, ["name", "home-before"]);
  const homeAfter = find(homeMedia, ["name", "home-after"]);

  return (
    <Section className={className} isFluid={isFluid} mod={mod}>
      {is === "hero" && <Media image={homeHero} />}
      {is === "before-after" && (
        <Box className="grid md:grid-cols-2">
          <Media image={homeBefore} />
          <Media image={homeAfter} />
        </Box>
      )}
      {children}
    </Section>
  );
};
