import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import clsx from "clsx";

import { Button, ButtonProps, ButtonPattern, TextPattern } from "components";
import { TrackEvent, trackEvent } from "utils";

import { ButtonLinkPattern } from "./types";
import {
  buttonLinkDefault,
  iconButtonLink,
  wrapperButtonLink,
} from "./ButtonLink.module.scss";

export interface ButtonLinkProps<E extends HTMLElement = HTMLAnchorElement>
  extends Omit<ButtonProps<E>, "align" | "as" | "is" | "ref" | "text" | "to"> {
  activeClassName?: string;
  button?: ButtonPattern;
  href?: string;
  is?: ButtonLinkPattern;
  isDisabled?: boolean;
  text?: TextPattern;
  to: string;
  tracking?: TrackEvent;
}

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps<HTMLAnchorElement>
>(
  (
    {
      activeClassName,
      button,
      children,
      className,
      href,
      is,
      isDisabled,
      rel,
      target,
      text,
      to,
      tracking,
      ...rest
    },
    ref
  ) => {
    const linkTo: string = href ? href : to;
    const isInternal: boolean = /^\/(?!\/)/.test(linkTo);
    const isFile: boolean = /\.[0-9a-z]+$/i.test(linkTo);

    const buttonLinkClassName = clsx(
      buttonLinkDefault,
      is === "icon" && iconButtonLink,
      is === "wrapper" && wrapperButtonLink,
      className
    );

    if (isInternal) {
      if (isFile) {
        return (
          <Button
            as="a"
            is={button}
            text={text}
            href={linkTo}
            rel={rel}
            target={target}
            disabled={isDisabled}
            {...(rest as ButtonProps)}
            className={buttonLinkClassName}
            innerRef={ref as any}
            onClick={() =>
              trackEvent(
                tracking?.category || "ButtonLink",
                tracking?.action || "File",
                tracking?.label || linkTo
              )
            }
          >
            {children}
          </Button>
        );
      }

      return (
        <Button
          as={GatsbyLink}
          is={button}
          text={text}
          to={linkTo}
          disabled={isDisabled}
          {...(rest as ButtonProps)}
          className={buttonLinkClassName}
          innerRef={ref as any}
          onClick={() =>
            trackEvent(
              tracking?.category || "ButtonLink",
              tracking?.action || "Internal",
              tracking?.label || linkTo
            )
          }
        >
          {children}
        </Button>
      );
    }

    return (
      <Button
        as="a"
        is={button}
        text={text}
        href={linkTo}
        rel={rel}
        target={target}
        disabled={isDisabled}
        {...(rest as ButtonProps)}
        className={buttonLinkClassName}
        innerRef={ref as any}
        onClick={() =>
          trackEvent(
            tracking?.category || "ButtonLink",
            tracking?.action || "External",
            tracking?.label || linkTo
          )
        }
      >
        {children}
      </Button>
    );
  }
);
