import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Link,
  LinkProps,
  TextPrimitive as Text,
  TextProps,
} from "components";

import {
  caption,
  captionHeading,
  captionMeta,
  captionSubheading,
} from "./Caption.module.scss";

export interface CaptionProps extends BoxProps {
  content?: Element | ReactNode;
  footer?: Element | ReactNode;
  header?: Element | ReactNode;
  heading?: string;
  headingProps?: TextProps;
  meta?: string;
  metaProps?: LinkProps | TextProps;
  subheading?: string;
  subheadingProps?: TextProps;
}

export const Caption: FC<CaptionProps> = ({
  as = "figcaption",
  children,
  className,
  content,
  footer,
  header,
  heading,
  headingProps,
  meta,
  metaProps,
  subheading,
  subheadingProps,
  ...rest
}) => (
  <Box as={as} {...(rest as BoxProps)} className={clsx(caption, className)}>
    {header}
    {meta &&
      (metaProps?.to ? (
        <Link
          text="subheading"
          {...(metaProps as LinkProps)}
          to={metaProps.to}
          className={clsx(captionMeta, metaProps?.className)}
        >
          {meta}
        </Link>
      ) : (
        <Text
          as="small"
          is="subheading"
          {...(metaProps as TextProps)}
          className={clsx(captionMeta, metaProps?.className)}
        >
          {meta}
        </Text>
      ))}
    {heading &&
      (headingProps?.to ? (
        <Link to={headingProps.to}>
          <Text
            as="h2"
            is="title"
            {...(headingProps as TextProps)}
            className={clsx(captionHeading, headingProps?.className)}
          >
            {heading}
          </Text>
        </Link>
      ) : (
        <Text
          as="h2"
          is="title"
          {...(headingProps as TextProps)}
          className={clsx(captionHeading, headingProps?.className)}
        >
          {heading}
        </Text>
      ))}
    {subheading && (
      <Text
        as="h3"
        is="subtitle"
        {...(subheadingProps as TextProps)}
        className={clsx(captionSubheading, subheadingProps?.className)}
      >
        {subheading}
      </Text>
    )}
    {content}
    {children}
    {footer}
  </Box>
);
