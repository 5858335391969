import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps, TextPattern } from "components";
import { TrackEvent, trackEvent } from "utils";

import { LinkPattern } from "./types";
import {
  linkDefault,
  linkDisabled,
  linkInherit,
  linkPrimary,
  linkSecondary,
} from "./Link.module.scss";

export interface LinkProps<E extends HTMLElement = HTMLAnchorElement>
  extends Omit<TextProps<E>, "align" | "as" | "is" | "ref" | "text" | "to"> {
  activeClassName?: string;
  href?: string;
  is?: LinkPattern;
  isDisabled?: boolean;
  text?: TextPattern;
  to: string;
  tracking?: TrackEvent;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps<HTMLAnchorElement>>(
  (
    {
      activeClassName,
      children,
      className,
      href,
      is,
      isDisabled,
      rel,
      target,
      text,
      to,
      tracking,
      ...rest
    },
    ref
  ) => {
    const linkTo: string = href ? href : to;
    const isInternal: boolean = /^\/(?!\/)/.test(linkTo);
    const isFile: boolean = /\.[0-9a-z]+$/i.test(linkTo);

    const linkClassName = clsx(
      linkDefault,
      is === "inherit" && linkInherit,
      is === "primary" && linkPrimary,
      is === "secondary" && linkSecondary,
      isDisabled && linkDisabled,
      className
    );

    if (isInternal) {
      if (isFile) {
        return (
          <Text
            as="a"
            is={text}
            href={linkTo}
            rel={rel}
            target={target}
            disabled={isDisabled}
            {...(rest as TextProps)}
            className={linkClassName}
            innerRef={ref as any}
            onClick={() =>
              trackEvent(
                tracking?.category || "Link",
                tracking?.action || "File",
                tracking?.label || linkTo
              )
            }
          >
            {children}
          </Text>
        );
      }

      return (
        <Text
          as={GatsbyLink}
          is={text}
          to={linkTo}
          disabled={isDisabled}
          {...(rest as TextProps)}
          className={linkClassName}
          innerRef={ref as any}
          onClick={() =>
            trackEvent(
              tracking?.category || "Link",
              tracking?.action || "Internal",
              tracking?.label || linkTo
            )
          }
        >
          {children}
        </Text>
      );
    }

    return (
      <Text
        as="a"
        is={text}
        href={linkTo}
        rel={rel}
        target={target}
        disabled={isDisabled}
        {...(rest as TextProps)}
        className={linkClassName}
        innerRef={ref as any}
        onClick={() =>
          trackEvent(
            tracking?.category || "Link",
            tracking?.action || "External",
            tracking?.label || linkTo
          )
        }
      >
        {children}
      </Text>
    );
  }
);
