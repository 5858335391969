import React, { FC } from "react";
import clsx from "clsx";

import {
  Section,
  SectionProps,
  ContainerPrimitive as Container,
} from "components";
import { BrandProps } from "containers";
import { ThemeSwitchProps, useTheme } from "contexts";
import {
  LayoutFooter,
  LayoutFooterProps,
  LayoutHeader,
  LayoutHeaderProps,
  LayoutMain,
  LayoutMainProps,
} from "layouts";
import { GatsbyImageProps, GatsbyLocation } from "types";

import {
  panelLayoutContainer,
  panelLayoutFooter,
  panelLayoutHeader,
  panelLayoutPanel,
  panelLayoutWrapper,
} from "./PanelLayout.module.scss";

export interface PanelLayoutProps
  extends Omit<SectionProps, "footer" | "header"> {
  brand?: BrandProps;
  footer?: LayoutFooterProps;
  header?: LayoutHeaderProps;
  image?: GatsbyImageProps;
  isMDX?: boolean;
  location?: GatsbyLocation;
  main?: LayoutMainProps;
  showMenu?: boolean;
  themeSwitch?: ThemeSwitchProps;
}

export const PanelLayout: FC<PanelLayoutProps> = ({
  brand,
  children,
  className,
  container,
  footer,
  header,
  image,
  is = "layout",
  isMDX,
  location,
  main,
  showMenu = false,
  themeSwitch,
  ...rest
}) => {
  const { theme } = useTheme();

  return (
    <Section
      as="div"
      is={is}
      color="primary"
      {...(rest as SectionProps)}
      container={{
        as: "div",
        ...container,
        className: clsx(panelLayoutContainer, container?.className),
      }}
      className={clsx(panelLayoutWrapper, theme, className)}
    >
      <Container is="panel" color="secondary" className={panelLayoutPanel}>
        <LayoutHeader
          color="secondary"
          isFluid={true}
          location={location}
          mod="compact transparent"
          showMenu={showMenu}
          {...header}
          className={clsx(panelLayoutHeader, header?.className)}
        />
        <LayoutMain isMDX={isMDX} {...main}>
          {children}
        </LayoutMain>
        <LayoutFooter
          color="secondary"
          isFluid={true}
          location={location}
          mod="compact transparent"
          themeSwitch={themeSwitch}
          {...footer}
          className={clsx(panelLayoutFooter, footer?.className)}
        />
      </Container>
    </Section>
  );
};
