// extracted by mini-css-extract-plugin
export var defaultText = "Text-module--defaultText--bQrXb";
export var bodyText = "Text-module--bodyText--34PFQ";
export var captionText = "Text-module--captionText--X0E6o";
export var headingText = "Text-module--headingText--3o2tD";
export var heroText = "Text-module--heroText--3Y0ls";
export var legendText = "Text-module--legendText--No50O";
export var metaText = "Text-module--metaText--3YBp6";
export var subheadingText = "Text-module--subheadingText--qIVzK";
export var subtitleText = "Text-module--subtitleText--1L-T5";
export var titleText = "Text-module--titleText--1plYe";
export var textXs = "Text-module--textXs--2Op5o";
export var textSm = "Text-module--textSm--b0SoZ";
export var textMd = "Text-module--textMd--1Er9-";
export var textLg = "Text-module--textLg--19BB0";
export var textXl = "Text-module--textXl--2b5ah";
export var text2xl = "Text-module--text2xl--1Pnz8";
export var text3xl = "Text-module--text3xl--bmcEF";
export var text4xl = "Text-module--text4xl--1-yTo";
export var text5xl = "Text-module--text5xl--339vp";