import React, { FC } from "react";

import { LinkProps, Nav, NavLink, NavProps } from "components";
import { GatsbyLocation } from "types";

export interface HeaderMenuProps extends NavProps {
  link?: LinkProps;
  location?: GatsbyLocation;
  showDefaultNavLinks?: boolean;
}

export const HeaderMenu: FC<HeaderMenuProps> = ({
  children,
  className,
  link,
  location,
  showDefaultNavLinks = true,
  ...rest
}) => (
  <Nav className={className} {...(rest as NavProps)}>
    {showDefaultNavLinks && (
      <>
        <NavLink to="/about" {...link}>
          About
        </NavLink>
        <NavLink to="/contact" {...link}>
          Contact
        </NavLink>
      </>
    )}
    {children}
  </Nav>
);
