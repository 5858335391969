import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  BoxColor,
  BoxPrimitive as Box,
  BoxProps,
  ButtonColor,
  ButtonLink,
  ButtonLinkProps,
  Media,
  MediaProps,
  TextPrimitive as Text,
  TextProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

import {
  featuredCTA,
  featuredCTAMedia,
  featuredCTAContent,
  featuredCTAHeading,
} from "./FeaturedCTA.module.scss";

export interface FeaturedCTAProps
  extends Omit<BoxProps, "color" | "label" | "media"> {
  attribute?: Element | ReactNode | string;
  button?: ButtonLinkProps;
  color?: BoxColor | ButtonColor;
  content?: Element | ReactNode | string;
  contentProps?: WrapperProps;
  cta?: BoxProps;
  footnote?: Element | ReactNode;
  heading?: Element | ReactNode | string;
  headingProps?: TextProps;
  label?: Element | ReactNode | string;
  media?: MediaProps;
  mediaProps?: WrapperProps;
  to?: string;
}

export const FeaturedCTA: FC<FeaturedCTAProps> = ({
  attribute,
  button,
  children,
  className,
  color = "secondary",
  content,
  contentProps,
  footnote,
  heading,
  headingProps,
  label,
  media,
  mediaProps,
  to,
  ...rest
}) => (
  <Box
    color={color as BoxColor}
    {...(rest as BoxProps)}
    className={clsx(featuredCTA, className)}
  >
    {media && (
      <Wrapper
        as="div"
        {...mediaProps}
        className={clsx(featuredCTAMedia, mediaProps?.className)}
      >
        {to ? (
          <ButtonLink is="wrapper" className={"w-full"} to={to}>
            <Media mod="background" {...(media as MediaProps)} />
          </ButtonLink>
        ) : (
          <Media mod="background" {...(media as MediaProps)} />
        )}
        {attribute}
      </Wrapper>
    )}
    {children}
    <Wrapper
      as="div"
      {...contentProps}
      className={clsx("container", featuredCTAContent, contentProps?.className)}
    >
      {heading && (
        <Text
          as="h2"
          is="title"
          {...headingProps}
          className={clsx(featuredCTAHeading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {content}
      {label && to && (
        <ButtonLink
          color={color as ButtonColor}
          {...(button as ButtonLinkProps)}
          to={to}
        >
          {label}
        </ButtonLink>
      )}
      {footnote}
    </Wrapper>
  </Box>
);
