import React, { FC } from "react";
import { getSrc } from "gatsby-plugin-image";
import { find } from "lodash";

import { Container, PageHero, PageHeroProps } from "components";
import { Contact, SEO, SEOProps } from "containers";
import { DefaultLayout } from "layouts";
import { useMediaQuery } from "graphql";

import { PageTemplateProps } from "./types";

export const PageTemplate: FC<PageTemplateProps> = ({
  children,
  isMDX = true,
  location,
  pageContext: {
    excerpt,
    frontmatter: {
      contact,
      description,
      hero,
      layout,
      main,
      page,
      seo,
      showFooter = true,
      showHeader = true,
      subtitle,
      title,
    },
  },
}) => {
  const media = useMediaQuery();
  const image = find(media, ["name", `${page}-hero`]);

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: image && getSrc(image.childImageSharp.gatsbyImageData),
    url: location?.href,
    ...seo,
  };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout location={location} isMDX={isMDX} main={main} {...layout}>
        {showHeader && (
          <PageHero
            color="primary"
            {...(hero as PageHeroProps)}
            caption={{
              heading: title,
              subheading: subtitle,
              ...hero?.caption,
            }}
            media={
              image && {
                image,
                ...hero?.media,
              }
            }
          />
        )}
        {main?.is === "content" ? (
          <Container
            as="article"
            is="content"
            className="py-8 md:py-12 xl:py-16"
          >
            {children}
          </Container>
        ) : (
          children
        )}
        {showFooter && <Contact location={location} {...contact} />}
      </DefaultLayout>
    </>
  );
};

export default PageTemplate;
