import React, { FC } from "react";
import clsx from "clsx";

import { BoxPrimitive as Box, BoxProps } from "components";

export type FlexBoxPattern = "default" | "full";

export interface FlexBoxProps extends BoxProps {
  is?: FlexBoxPattern;
}

export const FlexBox: FC<FlexBoxProps> = ({
  children,
  className,
  is = "default",
  ...rest
}) => (
  <Box
    {...(rest as BoxProps)}
    className={clsx(
      "flex",
      is === "default" && "flex-1",
      is === "full" && "flex-full",
      className
    )}
  >
    {children}
  </Box>
);
