"use-strict";

import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { ThemeProvider } from "./src/contexts";

import "./assets/styles/main.scss";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <HelmetProvider>{element}</HelmetProvider>
  </ThemeProvider>
);
