import { useStaticQuery, graphql } from "gatsby";

export interface SiteKeys {
  site: {
    siteMetadata: {
      recaptchaKey?: string;
    };
  };
}

export const useSiteKeysQuery = () => {
  const {
    site: { siteMetadata },
  }: SiteKeys = useStaticQuery(
    graphql`
      query SiteKeysQuery {
        site {
          siteMetadata {
            recaptchaKey
          }
        }
      }
    `
  );

  return siteMetadata;
};
