import React, { FC } from "react";
import clsx from "clsx";

import { Link, LinkProps, ListPrimitive as List, ListProps } from "components";
import { GatsbyLocation } from "types";

import {
  activeFooterMenuLink,
  footerMenu,
  footerMenuLink,
} from "./FooterMenu.module.scss";

export interface FooterMenuProps extends ListProps {
  link?: LinkProps;
  location?: GatsbyLocation;
  nav?: ListProps;
  showNavLinks?: boolean;
}

export const FooterMenu: FC<FooterMenuProps> = ({
  children,
  className,
  link,
  location,
  nav,
  showNavLinks = true,
  ...rest
}) => (
  <List
    as="nav"
    {...(rest as ListProps)}
    className={clsx(footerMenu, className)}
  >
    {children}
    {showNavLinks && (
      <>
        <Link
          activeClassName={activeFooterMenuLink}
          {...(link as LinkProps)}
          className={clsx("md:order-1", footerMenuLink, link?.className)}
          to="/about"
        >
          About
        </Link>
        <Link
          activeClassName={activeFooterMenuLink}
          {...(link as LinkProps)}
          className={clsx("md:order-3", footerMenuLink, link?.className)}
          to="/contact"
        >
          Contact
        </Link>
      </>
    )}
  </List>
);
