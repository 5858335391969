import clsx from "clsx";

import { TextPattern, TextSize } from "./types";

import {
  bodyText,
  captionText,
  headingText,
  heroText,
  legendText,
  metaText,
  subheadingText,
  subtitleText,
  titleText,
  textXs,
  textSm,
  textMd,
  textLg,
  textXl,
  text2xl,
  text3xl,
  text4xl,
  text5xl,
} from "./Text.module.scss";

export const getTextSize = (size: TextSize) => {
  switch (size) {
    case "xs":
      return textXs;
    case "sm":
      return textSm;
    case "lg":
      return textLg;
    case "xl":
      return textXl;
    case "2xl":
      return text2xl;
    case "3xl":
      return text3xl;
    case "4xl":
      return text4xl;
    case "5xl":
      return text5xl;
    case "md":
    default:
      return textMd;
  }
};

export const getTextPattern = (pattern: TextPattern, size?: TextSize) => {
  switch (pattern) {
    case "caption":
      return clsx(captionText, size ? getTextSize(size) : getTextSize("xs"));
    case "heading":
      return clsx(headingText, size ? getTextSize(size) : getTextSize("3xl"));
    case "hero":
      return clsx(heroText, size ? getTextSize(size) : getTextSize("4xl"));
    case "legend":
      return clsx(legendText, size ? getTextSize(size) : getTextSize("5xl"));
    case "meta":
      return clsx(metaText, size ? getTextSize(size) : getTextSize("sm"));
    case "subheading":
      return clsx(subheadingText, size ? getTextSize(size) : getTextSize("xl"));
    case "subtitle":
      return clsx(subtitleText, size ? getTextSize(size) : getTextSize("lg"));
    case "title":
      return clsx(titleText, size ? getTextSize(size) : getTextSize("2xl"));
    case "body":
    default:
      return clsx(bodyText, size ? getTextSize(size) : getTextSize("md"));
  }
};
