import React, { FC } from "react";
import clsx from "clsx";

import {
  FeaturedCTA,
  FeaturedCTAProps,
  HeroPattern,
  PageHero,
  PageHeroProps,
  PageHeroPattern,
  Link,
} from "components";

import {
  landingPageHero,
  landingPageHeroCaption,
  landingPageHeroContainer,
  landingPageHeroCTA,
  landingPageHeroHeading,
  landingPageHeroMedia,
  landingPageHeroMeta,
  landingPageHeroSubheading,
} from "./LandingPageHero.module.scss";

export interface LandingPageHeroProps
  extends Omit<PageHeroProps, "is" | "hero"> {
  cta?: FeaturedCTAProps;
  hero?: HeroPattern;
  pageHero?: PageHeroPattern;
  subtitle?: string;
  url?: string;
}

export const LandingPageHero: FC<LandingPageHeroProps> = ({
  caption,
  children,
  className,
  container,
  cta,
  hero,
  isFluid = true,
  media,
  pageHero,
  subtitle,
  url,
  vh = "full",
  ...rest
}) => (
  <PageHero
    isFluid={isFluid}
    caption={{
      ...caption,
      className: clsx("container", landingPageHeroCaption, caption?.className),
      metaProps: {
        ...caption?.metaProps,
        as: Link,
        to: url,
        className: clsx(landingPageHeroMeta, caption?.metaProps?.className),
      },
      headingProps: {
        is: "hero",
        ...caption?.headingProps,
        className: clsx(
          landingPageHeroHeading,
          caption?.headingProps?.className
        ),
      },
      subheadingProps: {
        ...caption?.subheadingProps,
        className: clsx(
          landingPageHeroSubheading,
          caption?.subheadingProps?.className
        ),
      },
    }}
    container={{
      is: "fluid",
      className: clsx(landingPageHeroContainer, container?.className),
      ...container,
    }}
    is={pageHero}
    hero={hero}
    media={{
      ...media,
      className: clsx(landingPageHeroMedia, media?.className),
    }}
    vh={vh}
    {...(rest as PageHeroProps)}
    className={clsx(landingPageHero, className)}
  >
    {children}
    <FeaturedCTA
      {...cta}
      className={clsx(landingPageHeroCTA, cta?.className)}
    />
  </PageHero>
);
