import React, { FC } from "react";
import clsx from "clsx";

import {
  BoxColor,
  Link,
  LinkPattern,
  Section,
  SectionProps,
  TextPrimitive as Text,
  TextProps,
} from "components";
import { ContactForm, ContactFormProps } from "containers";
import { GatsbyLocation } from "types";
import { useSiteMetadataQuery } from "graphql";

import {
  contactContainer,
  contactHeading,
  contactSubheading,
  contactForm,
  contactFormFootnote,
} from "./Contact.module.scss";

export interface ContactProps extends Omit<SectionProps, "color" | "form"> {
  color?: BoxColor | LinkPattern;
  form?: ContactFormProps;
  heading?: string;
  headingProps?: TextProps;
  location?: GatsbyLocation;
  showForm?: boolean;
  subheading?: string;
  subheadingProps?: TextProps;
}

export const Contact: FC<ContactProps> = ({
  as = "footer",
  is = "heel",
  children,
  className,
  color = "secondary",
  container,
  form,
  heading = "Contact us to get a quote or consultation.",
  headingProps,
  location,
  showForm = true,
  subheading,
  subheadingProps,
  ...rest
}) => {
  const { organization } = useSiteMetadataQuery();

  return (
    <Section
      as={as}
      is={is}
      color={color as BoxColor}
      container={{
        ...container,
        className: clsx(contactContainer, container?.className),
      }}
      className={className}
      {...(rest as SectionProps)}
    >
      {heading && (
        <Text
          as="h3"
          is="title"
          {...headingProps}
          className={clsx(contactHeading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {subheading && (
        <Text
          as="h4"
          is="subtitle"
          {...subheadingProps}
          className={clsx(contactSubheading, subheadingProps?.className)}
        >
          {subheading}
        </Text>
      )}
      {showForm && (
        <ContactForm
          {...form}
          className={clsx(contactForm, form?.className)}
          location={location}
        />
      )}
      {organization?.email && (
        <Link
          is={color as LinkPattern}
          className={contactFormFootnote}
          to={`mailto:${organization.email}`}
        >
          {`${showForm && "or "}send us an email.`}
        </Link>
      )}
    </Section>
  );
};
