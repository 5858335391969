import React, { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import { kebabCase } from "lodash";

import { BoxProps } from "components";
import { useBrandQuery } from "graphql";
import { GatsbyImageProps } from "types";

import { brandLogo } from "./BrandLogo.module.scss";

export type BrandLogoColor = "dark" | "light";

export interface BrandLogoProps extends Omit<BoxProps, "color"> {
  color?: BrandLogoColor;
  image?: GatsbyImageProps;
}

export const BrandLogo: FC<BrandLogoProps> = ({ className, color, image }) => {
  const { logo, logoDark, logoLight, name } = useBrandQuery();

  switch (color) {
    case "dark":
      if (!logoDark?.childImageSharp) return null;
      const altDark = kebabCase(`${name}-${logoDark.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={altDark}
          className={clsx(brandLogo, className)}
          image={logoDark.childImageSharp.gatsbyImageData}
        />
      );
    case "light":
      if (!logoLight?.childImageSharp) return null;
      const altLight = kebabCase(`${name}-${logoLight.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={altLight}
          className={clsx(brandLogo, className)}
          image={logoLight.childImageSharp.gatsbyImageData}
        />
      );
    default:
      if (!logo?.childImageSharp) return null;
      const alt = kebabCase(`${name}-${logo.name}`);

      return (
        <GatsbyImage
          {...image}
          alt={alt}
          className={clsx(brandLogo, className)}
          image={logo.childImageSharp.gatsbyImageData}
        />
      );
  }
};
