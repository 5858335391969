import React, { FC } from "react";
import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";

import { WrapperPrimitive as Wrapper, WrapperProps } from "components";
import { GatsbyImageProps } from "types";

import {
  defaultMedia,
  imageMedia,
  mediaBackground,
  mediaBlur,
  mediaFixed,
  mediaGradient,
  blurImage,
  fixedImage,
} from "./Media.module.scss";

export type MediaPattern = "image" | "pattern" | "video";

export type MediaModifier =
  | "background"
  | "blur"
  | "fixed"
  | "gradient"
  | string;

export interface MediaProps<Media extends HTMLElement = HTMLDivElement>
  extends Omit<WrapperProps<Media>, "image" | "is"> {
  image?: GatsbyImageProps;
  is?: MediaPattern;
  mod?: MediaModifier;
}

export const Media: FC<MediaProps> = ({
  as = "div",
  children,
  className,
  image,
  is = "image",
  mod,
  ...rest
}) => (
  <Wrapper
    as={as}
    {...(rest as WrapperProps)}
    className={clsx(
      defaultMedia,
      is === "image" && imageMedia,
      // is === "pattern" && patternMedia,
      // is === "video" && videoMedia,
      mod?.includes("background") && mediaBackground,
      mod?.includes("blur") && mediaBlur,
      mod?.includes("fixed") && mediaFixed,
      mod?.includes("gradient") && mediaGradient,
      className
    )}
  >
    {image && (
      <GatsbyImage
        alt={image.name || `media-${is}${mod ? `-${mod}` : ""}`}
        image={image.childImageSharp.gatsbyImageData}
        className={clsx(
          mod?.includes("blur") && blurImage,
          mod?.includes("fixed") && fixedImage,
          image.className
        )}
      />
    )}
    {children}
  </Wrapper>
);
