import React, { FC } from "react";
import clsx from "clsx";

import {
  ButtonProps,
  Link,
  LinkProps,
  ListPrimitive as List,
  ListProps,
} from "components";
import { ThemeSwitch } from "contexts";

import { navLink, navList } from "./Nav.module.scss";

export interface NavProps extends ListProps {
  isMenuOpened?: boolean;
  showMenuToggle?: boolean;
  showThemeToggle?: boolean;
  themeSwitch?: ButtonProps;
}

export const NavLink: FC<LinkProps> = ({
  activeClassName = "underline text-accent",
  children,
  className,
  color,
  text = "meta",
  ...rest
}) => (
  <Link
    activeClassName={activeClassName}
    color={color}
    text={text}
    {...(rest as LinkProps)}
    className={clsx(navLink, className)}
  >
    {children}
  </Link>
);

export const Nav: FC<NavProps> = ({
  as = "nav",
  children,
  className,
  is = "inline",
  isMenuOpened,
  showMenuToggle,
  showThemeToggle,
  themeSwitch,
  ...rest
}) => (
  <>
    <List
      as={as}
      is={is}
      {...(rest as ListProps)}
      className={clsx(navList, className)}
    >
      {children}
    </List>
    {showThemeToggle && <ThemeSwitch {...themeSwitch} />}
  </>
);
