import React, { FC, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import clsx from "clsx";

import { Box, Form, FormProps, FormField, Text, encodeData } from "components";
import { useSiteKeysQuery } from "graphql";
import { trackEvent } from "utils";

import {
  contactForm,
  contactFormFields,
  contactFormMessage,
  contactFormRecaptcha,
} from "./ContactForm.module.scss";

type FormData = {
  name: string;
  email: string;
  phone?: string;
  description: string;
};

export interface ContactFormProps extends FormProps {
  location?: any;
}

export const ContactForm: FC<ContactFormProps> = ({
  className,
  location,
  ...rest
}) => {
  const { recaptchaKey } = useSiteKeysQuery();
  const {
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    register,
    reset,
  } = useForm<FormData>();
  const [captured, setCaptured] = useState("");
  const [status, setStatus] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleSetStatus = (status: string) => {
    setStatus(status);
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    if (Object.keys(data).length > 0 && recaptchaRef.current) {
      const captchaResponse = await recaptchaRef.current.executeAsync();
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeData({
          "form-name": "contact",
          "bot-field": captured,
          "g-recaptcha-response": captchaResponse,
          ...data,
        }),
      };

      setStatus("sending");

      fetch("/", options)
        .then(() => {
          trackEvent(
            "ContactForm",
            "Contact",
            `Form data sent and received${
              location?.pathname && ` from: "${location.pathname}"`
            }`
          );
          setTimeout(() => {
            reset();
            handleSetStatus("sent");
          }, 3000);
        })
        .catch((error) => {
          trackEvent(
            "ContactForm",
            "Contact",
            `Form data failed to send${
              location?.pathname && ` from: "${location.pathname}"`
            }`
          );
          handleSetStatus("failed");
          console.error(error);
        });
    } else {
      trackEvent(
        "ContactForm",
        "Contact",
        `Form state had errors${
          location?.pathname && ` from: "${location.pathname}"`
        }`
      );
      handleSetStatus("failed");
      console.error(JSON.stringify(errors));
    }
  };

  return (
    <>
      <Form
        method="POST"
        name="contact"
        button="Contact Us"
        hasErrors={Object.keys(errors).length > 0}
        isSubmitSuccessful={isSubmitSuccessful}
        onSubmit={handleSubmit(onSubmit)}
        {...(rest as FormProps)}
        className={clsx(contactForm, className)}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <input
            name="bot-field"
            onChange={(event) => setCaptured(event.target.value)}
          />
        </div>
        <Box className={contactFormFields}>
          <FormField
            error={errors.name}
            errorMessage="Please enter your full name."
            label="Full name"
            name="name"
            register={register("name", { required: true })}
          />
          <FormField
            error={errors.email}
            errorMessage="Please enter an email address."
            label="Email address"
            name="email"
            register={register("email", { required: true })}
            type="email"
          />
          <FormField
            error={errors.phone}
            label="Phone number"
            name="phone"
            register={register("phone", { required: false })}
            type="tel"
          />
          <FormField
            error={errors.description}
            errorMessage="Please provide some details about your project or a reason for contacting us."
            label="Project details"
            name="projectDetails"
            register={register("description", { required: true })}
            type="textarea"
          />
          {recaptchaKey && (
            <ReCAPTCHA
              ref={recaptchaRef}
              className={contactFormRecaptcha}
              sitekey={recaptchaKey}
              size="invisible"
            />
          )}
        </Box>
      </Form>
      {status === "sending" && (
        <Text is="meta" className={contactFormMessage}>
          Please wait while we process your request...
        </Text>
      )}
      {status === "sent" && (
        <Text is="meta" className={contactFormMessage}>
          Thank you! We'll get back to you within one business day.
        </Text>
      )}
      {status === "failed" && (
        <Text is="meta" className={contactFormMessage}>
          Something went wrong, please try again or get in touch with us by
          phone or email.
        </Text>
      )}
    </>
  );
};
