// google analytics utils

declare global {
  interface Window {
    ga: any;
  }
}

export interface TrackEvent {
  category?: string;
  action?: string;
  label?: string;
}

export const trackEvent = (
  category: string,
  action: string,
  label: string
): any => {
  if (typeof window !== "undefined" && window?.ga) {
    window.ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
};
