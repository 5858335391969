import React, { forwardRef } from "react";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps, TextPattern } from "components";

export type ListItemPattern = "cell" | "default" | "wrapper";

export interface ListItemProps<E extends HTMLElement = HTMLLIElement>
  extends Omit<TextProps<E>, "is"> {
  is?: ListItemPattern;
  text?: TextPattern;
}

export const ListItemPrimitive = <E extends HTMLElement = HTMLLIElement>({
  as = "li",
  children,
  className,
  is,
  text,
  ...rest
}: ListItemProps<E>) => (
  <Text
    as={as}
    is={text}
    {...(rest as TextProps)}
    className={clsx("m-0", className)}
  >
    {children}
  </Text>
);

export const ListItem = forwardRef<HTMLLIElement, ListItemProps<HTMLLIElement>>(
  (props, ref) => <ListItemPrimitive {...props} innerRef={ref} />
);
