import React, { FC } from "react";
import clsx from "clsx";

import { Section, SectionProps } from "components";

import { HeroPattern } from "./types";

import { cardHero, defaultHero, pageHero, panelHero } from "./Hero.module.scss";

export interface HeroProps extends Omit<SectionProps, "is"> {
  is?: HeroPattern;
}

export const Hero: FC<HeroProps> = ({
  as = "header",
  caption,
  children,
  color,
  className,
  container,
  is = "page",
  vh,
  ...rest
}) => (
  <Section
    as={as}
    color={color}
    is="hero"
    vh={vh}
    {...(rest as SectionProps)}
    caption={{
      ...caption,
      headingProps: {
        as: "h1",
        ...caption?.headingProps,
      },
      subheadingProps: {
        as: "h2",
        ...caption?.subheadingProps,
      },
    }}
    container={{
      ...container,
      className: clsx("items-start", container?.className),
    }}
    className={clsx(
      is === "card" && cardHero,
      is === "default" && defaultHero,
      is === "page" && pageHero,
      is === "panel" && panelHero,
      className
    )}
  >
    {children}
  </Section>
);
