import React, { FC } from "react";
import { MDXProvider, MDXProviderComponents } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  Button,
  ButtonProps,
  ButtonLink,
  ButtonLinkProps,
  Caption,
  CaptionProps,
  CardPrimitive as Card,
  CardProps,
  ContainerPrimitive as Container,
  ContainerProps,
  FeaturedCTA,
  FeaturedCTAProps,
  FlexBox,
  FlexBoxProps,
  Form,
  FormProps,
  FormField,
  FormFieldProps,
  Hero,
  HeroProps,
  LandingPageHero,
  LandingPageHeroProps,
  Link,
  LinkProps,
  ListPrimitive as List,
  ListProps,
  ListItemPrimitive as ListItem,
  ListItemProps,
  Nav,
  NavLink,
  NavProps,
  PageHero,
  PageHeroProps,
  Section,
  SectionProps,
  SplitSection,
  SplitSectionProps,
  TextPrimitive as Text,
  TextProps,
  TextInput,
  TextInputProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";
import {
  Brand,
  BrandProps,
  ContactForm,
  ContactFormProps,
  Copyright,
  CopyrightProps,
  FooterMenu,
  FooterMenuProps,
  HeaderMenu,
  HeaderMenuProps,
  HomeMedia,
  HomeMediaProps,
  SEO,
  SEOProps,
  Values,
  ValuesProps,
} from "containers";

export const components: MDXProviderComponents = {
  a: (props: LinkProps) => <Link {...props} />,
  button: (props: ButtonProps) => <Button {...props} />,
  h1: (props: TextProps) => (
    <Text
      as="h1"
      className="mb-4 mt-8 md:mb-6 md:mt-10 xl:mb-8 xl:mt-12"
      is="hero"
      {...props}
    />
  ),
  h2: (props: TextProps) => (
    <Text
      as="h2"
      className="mb-4 mt-6 md:mb-6 md:mt-8 xl:mb-8 xl:mt-10"
      is="heading"
      {...props}
    />
  ),
  h3: (props: TextProps) => (
    <Text as="h3" className="my-4 md:my-6 xl:my-8" is="title" {...props} />
  ),
  h4: (props: TextProps) => (
    <Text as="h4" className="my-4 md:my-6 xl:my-8" is="subheading" {...props} />
  ),
  h5: (props: TextProps) => (
    <Text as="h5" className="my-4 md:my-6 xl:my-8" is="subtitle" {...props} />
  ),
  h6: (props: TextProps) => (
    <Text
      as="h6"
      className={clsx("my-4 md:my-6 xl:my-8", "font-semibold")}
      is="body"
      {...props}
    />
  ),
  li: (props: ListItemProps) => <ListItem {...props} />,
  ol: (props: ListProps) => (
    <List as="ol" className="mb-4 md:mb-6 xl:mb-8 pl-5" {...props} />
  ),
  p: (props: TextProps) => (
    <Text as="p" className="mb-4 md:mb-6 xl:mb-8" {...props} />
  ),
  ul: (props: ListProps) => (
    <List className="mb-4 md:mb-6 xl:mb-8 pl-5" {...props} />
  ),
};

export const componentShortcodes = {
  Box: (props: BoxProps) => <Box {...props} />,
  Button: (props: ButtonProps) => <Button {...props} />,
  ButtonLink: (props: ButtonLinkProps) => <ButtonLink {...props} />,
  Caption: (props: CaptionProps) => <Caption {...props} />,
  Card: (props: CardProps) => <Card {...props} />,
  Container: (props: ContainerProps) => <Container {...props} />,
  FeaturedCTA: (props: FeaturedCTAProps) => <FeaturedCTA {...props} />,
  FlexBox: (props: FlexBoxProps) => <FlexBox {...props} />,
  Form: (props: FormProps) => <Form {...props} />,
  FormField: (props: FormFieldProps) => <FormField {...props} />,
  Hero: (props: HeroProps) => <Hero {...props} />,
  LandingPageHero: (props: LandingPageHeroProps) => (
    <LandingPageHero {...props} />
  ),
  Link: (props: LinkProps) => <Link {...props} />,
  List: (props: ListProps) => <List {...props} />,
  ListItem: (props: ListItemProps) => <ListItem {...props} />,
  Nav: (props: NavProps) => <Nav {...props} />,
  NavLink: (props: LinkProps) => <NavLink {...props} />,
  PageHero: (props: PageHeroProps) => <PageHero {...props} />,
  Section: (props: SectionProps) => <Section {...props} />,
  SplitSection: (props: SplitSectionProps) => <SplitSection {...props} />,
  Text: (props: TextProps) => <Text {...props} />,
  TextInput: (props: TextInputProps) => <TextInput {...props} />,
  Wrapper: (props: WrapperProps) => <Wrapper {...props} />,
};

export const containerShortcodes = {
  Brand: (props: BrandProps) => <Brand {...props} />,
  ContactForm: (props: ContactFormProps) => <ContactForm {...props} />,
  Copyright: (props: CopyrightProps) => <Copyright {...props} />,
  FooterMenu: (props: FooterMenuProps) => <FooterMenu {...props} />,
  HeaderMenu: (props: HeaderMenuProps) => <HeaderMenu {...props} />,
  HomeMedia: (props: HomeMediaProps) => <HomeMedia {...props} />,
  SEO: (props: SEOProps) => <SEO {...props} />,
  Values: (props: ValuesProps) => <Values {...props} />,
};

export const shortcodes = {
  ...componentShortcodes,
  ...containerShortcodes,
};

export const mdxComponents = {
  ...components,
  ...shortcodes,
};

export type MDXPattern = "content" | "wrapper";

export interface MDXProps extends Omit<SectionProps | WrapperProps, "is"> {
  body?: string;
  components?: MDXProviderComponents;
  container?: ContainerProps;
  is?: MDXPattern;
}

export const MDX: FC<MDXProps> = ({
  as = "article",
  body,
  children,
  className,
  components,
  container,
  is,
  ...rest
}) => {
  if (is === "content") {
    return (
      <Section
        className={className}
        {...(rest as SectionProps)}
        container={{
          ...container,
          className: clsx(
            "flex flex-col col-wrap",
            "content-start items-stretch justify-start",
            container?.className
          ),
        }}
      >
        <MDXProvider components={{ ...mdxComponents, ...components }}>
          {body && <MDXRenderer>{body}</MDXRenderer>}
          {children}
        </MDXProvider>
      </Section>
    );
  }

  return (
    <Wrapper
      as={as}
      wrap="wrap"
      {...(rest as WrapperProps)}
      className={clsx("content-start items-stretch justify-start", className)}
    >
      <MDXProvider components={{ ...mdxComponents, ...components }}>
        {body && <MDXRenderer>{body}</MDXRenderer>}
        {children}
      </MDXProvider>
    </Wrapper>
  );
};
