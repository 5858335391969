import React, { FC } from "react";
import clsx from "clsx";

import {
  TextPrimitive as Text,
  TextProps,
  TextPattern,
  TextSize,
  getTextSize,
} from "components";

import { ButtonColor, ButtonPattern, ButtonType } from "./types";
import {
  containedButton,
  defaultButton,
  iconButton,
  outlinedButton,
  textButton,
  primaryButton,
  secondaryButton,
  inheritButton,
  transparentButton,
} from "./Button.module.scss";

export interface ButtonProps<E extends HTMLElement = HTMLButtonElement>
  extends Omit<TextProps<E>, "color" | "is" | "pattern"> {
  color?: ButtonColor;
  is?: ButtonPattern;
  size?: TextSize;
  text?: TextPattern;
  type?: ButtonType;
}

export const Button: FC<ButtonProps> = ({
  as = "button",
  children,
  className,
  color,
  is = "contained",
  size = "md",
  text,
  type,
  ...rest
}) => {
  const textSize: TextPattern = text ? text : getTextSize(size);

  return (
    <Text
      as={as}
      is={textSize}
      {...(rest as TextProps)}
      className={clsx(
        defaultButton,
        "transition-colors duration-250 ease-in-out",
        color === "primary" && primaryButton,
        color === "secondary" && secondaryButton,
        color === "inherit" && inheritButton,
        color === "transparent" && transparentButton,
        is === "contained" && containedButton,
        is === "icon" && iconButton,
        is === "outlined" && outlinedButton,
        is === "text" && textButton,
        size === "xs" && "border py-1 px-2 lg:px-3 font-light text-1/2",
        size === "sm" &&
          "border py-1 px-3 md:py-2 lg:px-4 font-normal text-3/4",
        size === "md" && "border-2 py-2 px-4 md:px-5 xl:py-3 xl:px-6 text-full",
        size === "lg" && "border-2 py-3 px-5 md:px-6 xl:py-4 xl:px-7",
        size === "xl" && "border-3 py-4 px-6 md:px-7 xl:py-5 xl:px-8",
        size === "2xl" && "border-3 py-5 px-7 md:px-8 xl:py-6 xl:px-9",
        size === "3xl" && "border-4 py-6 px-8 md:px-8 xl:py-7 xl:px-12",
        size === "4xl" && "border-5 py-7 px-9 md:px-8 xl:py-8 xl:px-14",
        size === "5xl" && "border-6 py-8 px-10 md:px-8 xl:py-9 xl:px-16",
        className
      )}
    >
      {children}
    </Text>
  );
};
