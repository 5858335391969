import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";

import {
  BoxColor,
  LinkPattern,
  SplitSection,
  SplitSectionProps,
} from "components";
import { Brand, BrandProps, HeaderMenu, HeaderMenuProps } from "containers";
import { GatsbyLocation } from "types";

import {
  layoutHeader,
  layoutHeaderSplit,
  fixedLayoutHeader,
  transparentLayoutHeader,
} from "./LayoutHeader.module.scss";

export interface LayoutHeaderProps extends Omit<SplitSectionProps, "color"> {
  activeClassName?: string;
  brand?: BrandProps;
  color?: BoxColor | LinkPattern;
  isFixed?: boolean;
  isHidden?: boolean;
  location?: GatsbyLocation;
  menu?: HeaderMenuProps;
  showBrand?: boolean;
  showMenu?: boolean;
  textClassName?: string;
}

export const LayoutHeader: FC<LayoutHeaderProps> = ({
  as = "header",
  brand,
  children,
  className,
  color = "primary",
  container,
  is = "navbar",
  isFixed = false,
  isHidden = false,
  left,
  leftProps,
  location,
  position,
  menu,
  right,
  rightProps,
  showBrand = true,
  showMenu = true,
  split,
  textClassName,
  ...rest
}) => {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  if (isHidden) return null;

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = window.pageYOffset;
      // scrolling down
      if (currentPosition > scrollTop) {
        // scrolled to bottom
        setHeaderFixed(true);
      } else {
        // scrolled to top
        if (window.scrollY === 0) {
          setHeaderFixed(false);
        } else {
          setHeaderFixed(true);
        }
      }

      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <SplitSection
      as={as}
      is={is}
      color={color as BoxColor}
      {...(rest as SplitSectionProps)}
      className={clsx(
        layoutHeader,
        isFixed || headerFixed ? fixedLayoutHeader : transparentLayoutHeader,
        className
      )}
      container={container}
      left={left ? left : showBrand && <Brand color={color} {...brand} />}
      leftProps={{
        ...leftProps,
        className: clsx("flex-none", leftProps?.className),
      }}
      right={
        right
          ? right
          : showMenu && (
              <HeaderMenu
                {...menu}
                link={{ is: color as LinkPattern, ...menu?.link }}
              />
            )
      }
      rightProps={{
        ...rightProps,
        className: clsx(
          "justify-end",
          layoutHeaderSplit,
          rightProps?.className
        ),
      }}
      split={{
        ...split,
        className: clsx("order-0", layoutHeaderSplit, split?.className),
      }}
    >
      {children}
    </SplitSection>
  );
};
