import React, { forwardRef } from "react";
import clsx from "clsx";

import { BoxPrimitive as Box, BoxProps } from "components";

import { WrapperPattern, WrapperWrap } from "./types";

export interface WrapperProps<E extends HTMLElement = HTMLDivElement>
  extends Omit<BoxProps<E>, "wrap"> {
  is?: WrapperPattern;
  wrap?: WrapperWrap;
}

export const WrapperPrimitive = <E extends HTMLElement = HTMLDivElement>({
  as = "section",
  children,
  className,
  is = "col",
  wrap = "nowrap",
  ...rest
}: WrapperProps<E>) => (
  <Box
    as={as}
    {...(rest as BoxProps)}
    className={clsx(
      is === "col" && "flex flex-col",
      is === "col-reverse" && "flex flex-col-reverse",
      is === "row" && "flex flex-row",
      is === "row-reverse" && "flex flex-row-reverse",
      wrap === "nowrap" && "flex-nowrap",
      wrap === "reverse" && "flex-wrap-reverse",
      wrap === "wrap" && "felx-wrap",
      className
    )}
  >
    {children}
  </Box>
);

export const Wrapper = forwardRef<HTMLDivElement, WrapperProps<HTMLDivElement>>(
  (props, ref) => <WrapperPrimitive {...props} innerRef={ref} />
);
