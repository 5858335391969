import React, { FC } from "react";
import {
  GiBlockHouse,
  GiStabbedNote,
  GiToolbox,
  GiTrafficCone,
} from "react-icons/gi";

import { Box, Card, Section, SectionProps, Text } from "components";

import {
  valueCardIcon,
  valueCardTitle,
  valuesGrid,
} from "./Values.module.scss";

export interface ValuesProps extends SectionProps {
  heading?: string;
}

export const Values: FC<ValuesProps> = ({
  caption,
  children,
  className,
  color = "primary",
  heading,
  ...rest
}) => (
  <Section
    caption={{ heading, ...caption }}
    className={className}
    color={color}
    {...(rest as SectionProps)}
  >
    <Box className={valuesGrid}>
      <Card>
        <GiTrafficCone className={valueCardIcon} />
        <Text as="h4" is="subtitle" className={valueCardTitle}>
          High Safety Standards
        </Text>
        <Text as="p">
          Safety is our number one concern, as evident in our state approved
          design and engineering specs, and the condition of our worksites.
        </Text>
      </Card>
      <Card>
        <GiBlockHouse className={valueCardIcon} />
        <Text as="h4" is="subtitle" className={valueCardTitle}>
          Modern Design Infused With Tradition
        </Text>
        <Text as="p">
          We do our research into how culture impacts design and create our
          ideas with a modern eye and an originally traditional twist.
        </Text>
      </Card>
      <Card>
        <GiToolbox className={valueCardIcon} />
        <Text as="h4" is="subtitle" className={valueCardTitle}>
          Experienced and Educated Crews
        </Text>
        <Text as="p">
          We work with very experienced, educated people and we closely mentor
          motivated talent to produce high quality work.
        </Text>
      </Card>
      <Card>
        <GiStabbedNote className={valueCardIcon} />
        <Text as="h4" is="subtitle" className={valueCardTitle}>
          Clear and Authentic Contracts
        </Text>
        <Text as="p">
          We believe in our work and we want our clients to be happy, so we
          write our contracts in very legible and direct language.
        </Text>
      </Card>
    </Box>
    {children}
  </Section>
);
