import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import { FlexBox, FlexBoxProps, Section, SectionProps } from "components";

export interface SplitSectionProps extends SectionProps {
  isResponsive?: boolean;
  isReversed?: boolean;
  left?: Element | ReactNode;
  leftProps?: FlexBoxProps;
  right?: Element | ReactNode;
  rightProps?: FlexBoxProps;
  split?: FlexBoxProps;
}

export const SplitSection: FC<SplitSectionProps> = ({
  children,
  className,
  container,
  isResponsive,
  isReversed,
  left,
  leftProps,
  right,
  rightProps,
  split,
  ...rest
}) => (
  <Section
    className={className}
    container={{
      ...container,
      className: clsx(
        isResponsive
          ? "flex flex-col flex-nowrap md:flex-row md:flex-wrap items-start"
          : "flex flex-row flex-wrap items-center",
        "content-between justify-between",
        container?.className
      ),
    }}
    {...(rest as SectionProps)}
  >
    {isReversed
      ? right && (
          <FlexBox {...split} {...rightProps}>
            {right}
          </FlexBox>
        )
      : left && (
          <FlexBox {...split} {...leftProps}>
            {left}
          </FlexBox>
        )}
    {children}
    {isReversed
      ? left && (
          <FlexBox {...split} {...leftProps}>
            {left}
          </FlexBox>
        )
      : right && (
          <FlexBox {...split} {...rightProps}>
            {right}
          </FlexBox>
        )}
  </Section>
);
